import React, { useEffect } from "react";
import { useAppSelector } from "app/hooks";

declare global {
  interface Window {
    CCEverywhere: any;
    define: any;
    exports: any;
    module: any;
  }
}

interface AdobeExpressEditorProps {
  imageUrl: string;
  onSave: (editedImageUrl: string) => void;
  onCancel: () => void;
}

const AdobeExpressEditor: React.FC<AdobeExpressEditorProps> = ({ imageUrl, onSave, onCancel }) => {
  const adobeSdkEditor = useAppSelector((state) => state.uiV2.adobeSdkEditor);

  useEffect(() => {
    const handleEditor = async () => {
      if (!adobeSdkEditor?.editor) {
        console.error("Adobe Express editor not initialized");
        return;
      }

      try {
        const callbacks = {
          onCancel: () => {
            onCancel();
          },
          onPublish: (intent: any, publishParams: any) => {
            if (publishParams.asset && publishParams.asset[0]) {
              onSave(publishParams.asset[0].data);
            }
          },
          onError: (error: any) => {
            console.error("Error during editing:", error);
            onCancel();
          }
        };

        const appConfig = {
          selectedCategory: "media",
          callbacks
        };

        const exportConfig = [
          {
            id: "save-modified-asset",
            label: "Save image",
            action: { target: "publish" },
            style: { uiType: "button" }
          }
        ];

        const docConfig = {
          asset: {
            data: imageUrl,
            dataType: "base64",
            type: "image"
          }
        };

        const containerConfig = {
          zIndex: 10
        };

        await adobeSdkEditor?.editor.createWithAsset(
          docConfig,
          appConfig,
          exportConfig,
          containerConfig
        );
      } catch (error) {
        console.error("Error in handleEdit:", error);
      }
    };
    if (adobeSdkEditor?.editor) {
      handleEditor();
    }
  }, [adobeSdkEditor?.editor]);

  return <></>;
};

export default AdobeExpressEditor;
