import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import styled, { useTheme } from "styled-components";

import { MediaLibraryTabs, SelectedImageContext } from "app/types/media";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button, Input } from "@nextui-org/react";
import { base64ToFile, fetchingStatus } from "app/utils/helpers";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { v4 as uuidv4 } from "uuid";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useMediaLibraryUploader from "app/pages/mediaLibrary/useMediaLibraryUploader";
import { FeatureFlag, FoldersContext } from "app/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactComponent as AdobeImage } from "app/assets/images/adobe.svg";
import { Progress } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";

const GenerateButton = styled(Button)`
  min-width: 150px;
`;
const AddButton = styled(Button)`
  min-width: 150px;
  position: absolute;
  bottom: 0px;
  z-index: 333;
  right: 0px;
`;

const StyledProgress = styled(Progress)`
  align-self: center;
  .ant-progress-text {
    color: ${({ theme }) => theme.gray11};
  }
`;

const ImagePreview = styled.div`
  width: 300px;
  max-width: 512px;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export interface MediaLibraryTabSDGenerateProps {
  onImageSelect: (value: string, context?: SelectedImageContext) => void;
  selectedUrl?: string;
  onTabChange: (value: string) => void;
}

const MediaGeneratorTab = ({ onImageSelect, onTabChange }: MediaLibraryTabSDGenerateProps) => {
  const [prompt, setPrompt] = useState<string>("");
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const flags = useFlags();
  const {
    importLoading,
    storeUrl,
    uploadedMedia,
    resetUploader,
    executeImport,
    progress,
    clearUserUploadedMedia
  } = useMediaLibraryUploader({
    context: FoldersContext.media
  });

  const mediaGenerateStatus = useAppSelector((state) => state.media.mediaGeneratorStatus);
  const mediaGeneratorResult = useAppSelector((state) => state.media.mediaGeneratorResult);
  const adobeSdkEditor = useAppSelector((state) => state.uiV2.adobeSdkEditor);
  const imageResultUrl = mediaGeneratorResult?.images?.[0]?.url;

  const loading = mediaGenerateStatus === fetchingStatus.loading;

  const onChangePromptValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };
  const handleGenerate = () => {
    if (flags[FeatureFlag.adobeImageFit]) {
      const module = adobeSdkEditor?.module || window.module || window.exports.module;
      if (module) {
        const callbacks = {
          onCancel: () => {
            console.log("cancelled");
          },
          onError: (err: any) => {
            console.error(err);
          },
          onPublish: (intent: any, publishParams: any) => {
            if (publishParams.asset && publishParams.asset[0]) {
              const editedImageUrl = publishParams.asset[0].data;
              const file = base64ToFile(editedImageUrl, new Date().toString());
              executeImport([file]);
            }
          }
        };

        const appConfig = {
          promptText: prompt,
          callbacks,
          designConfig: {
            size: {
              width: 512,
              height: 512
            }
          }
        };

        const exportConfig = [
          {
            action: {
              target: "publish",
              outputype: "URL",
              closeTargetOnExport: true
            },
            id: "save-to-host-app",
            type: "button",
            label: "Save in App",
            style: {
              uiType: "button"
            }
          }
        ];
        const containerConfig = {
          zIndex: 10000
        };

        module.createImageFromText(appConfig, exportConfig, containerConfig);
      }
    } else {
      dispatch(mediaActions.mediaGeneratorRequest({ prompt, orderId: uuidv4() }));
    }
  };
  const onSaveInMedia = () => {
    setUploadLoading(true);
    storeUrl(imageResultUrl as string);
  };

  useEffect(() => {
    (async () => {
      if (uploadedMedia) {
        onImageSelect(uploadedMedia.url, { url: uploadedMedia.url, handle: uploadedMedia.handle });
        setUploadLoading(false);
        resetUploader();
        clearUserUploadedMedia();
        onTabChange(MediaLibraryTabs.MyMedia);
      }
    })();
  }, [uploadedMedia]);

  return (
    <H1_FlexColumn
      flex="1 1 auto"
      overflow="hidden"
      gap="33px"
      height="100%"
      justify="flex-start"
      align="center"
      padding="24px"
    >
      <H1_FlexColumn gap="16px" width="100%">
        <Input
          size="lg"
          placeholder="Describe the image you want to generate..."
          value={prompt}
          onChange={onChangePromptValue}
          fullWidth
          disabled={loading}
        />
        <H1_FlexColumn flex={"1 0 auto"} height={"100px"} width={"150px"} alignSelf={"center"}>
          <GenerateButton
            color="primary"
            onClick={handleGenerate}
            disabled={loading}
            isLoading={loading}
            startContent={<AdobeImage />}
          >
            {imageResultUrl ? "Regenerate" : "Generate"}
          </GenerateButton>
        </H1_FlexColumn>
        <ConditionalRender condition={progress > 0}>
          <StyledProgress
            width={80}
            type="circle"
            trailColor={theme.blue1}
            strokeColor={theme.blue4}
            percent={progress || 0}
            showInfo
          />
        </ConditionalRender>
      </H1_FlexColumn>

      {imageResultUrl && (
        <H1_FlexRow position={"relative"}>
          <AddButton
            color="primary"
            onClick={onSaveInMedia}
            disabled={!prompt.trim() || loading}
            isLoading={importLoading || uploadLoading}
            size={"sm"}
          >
            {"Save"}
          </AddButton>
          <ImagePreview>
            <img src={imageResultUrl} alt={"Generated"} />
          </ImagePreview>
        </H1_FlexRow>
      )}
    </H1_FlexColumn>
  );
};

export default MediaGeneratorTab;
